import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { doPasswordReset } from "../../firebase/auth";
import { mapErrorMessage } from "../../components/auth/login/errorMessages";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      if (!isSubmitting) {
        setIsSubmitting(true);
        await doPasswordReset(email);
        setMessage("Password reset email sent. Redirecting to home page...");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      setMessage(mapErrorMessage(err.code, err.message));
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#E3E3E1] flex flex-col">
      <div className="flex-1 flex items-center justify-center px-4">
        <div className="w-full max-w-md bg-white rounded-3xl shadow-lg p-8 space-y-6">
          <div className="space-y-6">
            <Link 
              to="/" 
              className="block text-sm text-black hover:underline"
            >
              ← Back to Home
            </Link>
            <div className="text-center">
              <h3 className="text-2xl font-bold text-black">Reset Password</h3>
            </div>
          </div>

          <form onSubmit={onSubmit} className="space-y-5">
            <div>
              <label className="text-sm font-medium text-black">Email</label>
              <input
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mt-2 px-4 py-3 rounded-full border border-gray-300 focus:border-black focus:ring-0 text-black bg-transparent"
              />
            </div>

            {message && (
              <p
                className={`text-sm font-medium text-center ${
                  message.includes("sent") ? "text-green-600" : "text-red-600"
                }`}
              >
                {message}
              </p>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full py-3 px-4 rounded-full bg-black text-white font-medium text-base
                hover:bg-[#E3E3E1] hover:text-black hover:border hover:border-black 
                transition-colors duration-200 disabled:bg-gray-300 disabled:hover:bg-gray-300 
                disabled:text-gray-500 disabled:border-gray-300"
            >
              {isSubmitting ? "Submitting..." : "Reset Password"}
            </button>

            <div className="text-center">
              <Link to="/login" className="text-sm text-black hover:underline">
                Remembered your password? <span className="font-bold">Login</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
