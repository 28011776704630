import React, { useEffect, useState } from "react";
import SettingsItems from "./settingsItems";
import HowToPlayModal from "./howToPlayModal";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import gameIcon from "../../media/eyeq-icon.svg";
// import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../contexts/authContext";
import { doSignOut } from "../../firebase/auth";
// import { Timestamp } from "@firebase/firestore";
import { getLatestDocumentByCurrentUser } from "../../firebase/store";
import "../../styles/home.css";

const HomePage = ({ allowAccessToPlay }) => {
  const { userLoggedIn } = useAuth();
  const [showSettings, setShowSettings] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [userLogIn, setIsUserLogIn] = useState(false);
  const [result, setResult] = useState();
  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  // const isDesktop = useMediaQuery({ minWidth: 1024 });

  let navigate = useNavigate();

  useEffect(() => {
    const fetchLatestDocument = async () => {
      const doc = await getLatestDocumentByCurrentUser();

      if (!doc) {
        console.log("Nothing found");
        localStorage.clear();
        return;
      }

      const createdAt = doc.created_at.toDate(); // assuming created_at is a Firestore timestamp
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);

      const startOfYesterday = new Date(startOfToday);
      startOfYesterday.setDate(startOfYesterday.getDate() - 1);

      const endOfYesterday = new Date(startOfToday);
      endOfYesterday.setMilliseconds(-1);

      localStorage.clear();

      localStorage.setItem("gamesPlayed", doc.gamesPlayed);

      if (createdAt >= startOfToday) {
        console.log("It's from today, let's update and continue as is");
        localStorage.setItem("UserGamePlay", JSON.stringify(doc));
        setResult(doc);
      } else if (
        createdAt >= startOfYesterday &&
        createdAt <= endOfYesterday &&
        doc.status === "COMPLETED"
      ) {
        console.log(
          "Document is from yesterday and completed, updating streak"
        );
        localStorage.setItem("currentStreak", doc.currentStreak);
      } else {
        console.log("Old data");
        setResult(undefined);
      }
    };

    if (userLoggedIn) {
      fetchLatestDocument().catch(console.error);
      // setIsUserLogIn(true);
    } else {
      // setIsUserLogIn(false);
      let UserObject = localStorage.getItem("UserGamePlay");
      UserObject = JSON.parse(UserObject);

      const createdAt = new Date(parseInt(UserObject?.created_at));
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);

      if (createdAt < startOfDay) {
        console.log("Old data");
        localStorage.clear();
        setResult(undefined);
      } else {
        console.log("It's from today, let's update and continue as is");
        setResult(UserObject);
      }
      if (UserObject) {
        localStorage.setItem("gamesPlayed", UserObject?.gamesPlayed);
      }
    }
  }, [userLoggedIn]);

  const handleNavigate = (path) => {
    console.log("Allowing access to play");
    allowAccessToPlay();
    navigate(path);
  };

  // const insertUserEntry = async () => {
  //   try {
  //     let newEntryNow = {
  //       puzzle_id: "202331",
  //       status: "COMPLETED",
  //       currentQuestionIndex: 10,
  //       currentScore: 10,
  //       gameState: [
  //         ["11:00 am", "10:12 pm"],
  //         ["Grade 12", "Sophomores", "Grade 8"],
  //         ["July 1", "July 22"],
  //       ],
  //       hasSeenPicture: true,
  //       AvgScorePercentage: 10,
  //       currentStreak: 10,
  //       gamesPlayed: 10,
  //       isOnStreak: false,
  //       lastCompleted: "2020-01-20T08:00:00.000Z",
  //       maxStreak: 10,
  //       created_at: Timestamp.fromDate(new Date()),
  //     };

  //     await createNewUserEntry(newEntryNow);

  //     console.log("User entry created successfully:", result);
  //     return result;
  //   } catch (error) {
  //     console.error("Error creating user entry:", error);
  //     throw new Error("Error creating user entry");
  //   }
  // };

  // const testAPI = () => {
  //   insertUserEntry()
  //     .then((response) => {
  //       console.log("Insert response:", response);
  //     })
  //     .catch((error) => {
  //       console.error("Insert error:", error);
  //     });
  // };

  return (
    <div className="min-h-screen bg-[#E3E3E1] flex flex-col">
      <div className="w-full flex justify-end p-4">
        <button
          onClick={() => setShowSettings(!showSettings)}
          className="p-2.5 transition-transform duration-300 hover:scale-110"
        >
          <IoMdSettings
            size={24}
            className={`text-[#333] transform transition-transform duration-300 ${
              showSettings ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </button>
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 flex items-center justify-center">
          <div className="flex flex-col items-center max-w-4xl w-full px-5">
            <div className="w-[120px] h-[120px] mb-5">
              <img
                src={gameIcon}
                alt="logo of quiz app"
                className="w-full h-full object-contain"
              />
            </div>

            <h1 className="text-2xl md:text-3xl font-bold mb-2.5 text-center text-black">
              Fragments of Memory
            </h1>

            <p className="text-lg md:text-xl text-center text-[#222] mb-10 px-2.5 max-w-lg">
              Memorize and guess the right answers! You get 3 chances!
            </p>

            <div className="w-full flex flex-col md:flex-row items-center justify-center gap-3 md:gap-4 max-w-lg md:max-w-2xl px-4">
              <button
                onClick={() => setIsModalOpen(true)}
                className="w-4/5 md:w-48 py-3.5 px-4 rounded-full border border-black bg-[#E3E3E1] text-black font-medium text-base
                  hover:bg-black hover:text-white transition-colors duration-200"
              >
                How To Play
              </button>

              {userLoggedIn ? (
                <button
                  onClick={() => {
                    doSignOut().then(() => {
                      localStorage.clear();
                      window.location.reload();
                    });
                  }}
                  className="w-4/5 md:w-48 py-3.5 px-4 rounded-full border border-black bg-[#E3E3E1] text-black font-medium text-base
                    hover:bg-black hover:text-white transition-colors duration-200"
                >
                  Log Out
                </button>
              ) : (
                <button
                  onClick={() => handleNavigate("/login")}
                  className="w-4/5 md:w-48 py-3.5 px-4 rounded-full border border-black bg-[#E3E3E1] text-black font-medium text-base
                    hover:bg-black hover:text-white transition-colors duration-200"
                >
                  Log In
                </button>
              )}

              <button
                onClick={() => handleNavigate("/play")}
                className="w-4/5 md:w-48 py-3.5 px-4 rounded-full bg-black text-white font-medium text-base
                  hover:bg-[#E3E3E1] hover:text-black hover:border hover:border-black transition-colors duration-200"
              >
                {result?.status === "COMPLETED" ? "See Stats" : "Play"}
              </button>
            </div>
          </div>
        </div>

        <div className="w-full py-6 px-4">
          <div className="flex flex-col items-center gap-4">
            <p className="text-base text-[#666]">
              {new Date().toLocaleDateString("en-US", dateOptions)}
            </p>

            <p className="text-xs text-center text-[#666] max-w-lg">
              This site uses cookies to deliver and enhance the quality of its services
              and to analyse traffic.{" "}
              <a
                href="https://policies.google.com/technologies/cookies?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#007AFF] underline"
              >
                Learn more
              </a>
            </p>
          </div>
        </div>
      </div>

      {showSettings && (
        <>
          <div 
            className="settings-backdrop" 
            onClick={() => setShowSettings(false)}
          />
          <SettingsItems setShowSettings={setShowSettings} />
        </>
      )}
      <HowToPlayModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default HomePage;
