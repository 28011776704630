import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useRef, useEffect, useState } from "react";

const ZoomableImage = ({ src, imgW, imgH, onLoad }) => {
  const transformWrapperRef = useRef(null);
  const imageRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Handle image load event
  const handleImageLoad = () => {
    console.log('ZoomableImage: handleImageLoad called');
    setIsLoaded(true);
    onLoad?.();
  };

  // Check for cached image
  useEffect(() => {
    const img = imageRef.current;
    if (img) {
      console.log('ZoomableImage: Image ref available, complete:', img.complete);
      if (img.complete) {
        console.log('ZoomableImage: Image already loaded from cache');
        handleImageLoad();
      }
    }
  }, []);

  // Preload image
  useEffect(() => {
    if (src && !isLoaded) {
      console.log('ZoomableImage: Preloading image:', src);
      const img = new Image();
      img.onload = () => {
        console.log('ZoomableImage: Preloaded image loaded');
        handleImageLoad();
      };
      img.src = src;
    }
  }, [src, isLoaded]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        height: `calc(var(--vh, 1vh) * 100)`,
        width: "100%",
        border: "1px solid rgb(200, 200, 200)",
        borderRadius: "20px",
        overflow: "clip",
      }}
    >
      <TransformWrapper centerOnInit ref={transformWrapperRef}>
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            background: "rgb(247 243 243 / 57%)",
            height: "100%",
            cursor: "zoom-in",
          }}
        >
          <img
            ref={imageRef}
            style={{ width: "100%", maxHeight: imgH, cursor: "zoom-in" }}
            src={src}
            alt="zoomable"
            width={imgW}
            height={imgH}
            onLoad={handleImageLoad}
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

export default ZoomableImage;
