import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/home/home";
import Play from "./components/play/play";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ForgotPassword from "./components/auth/ForgotPassword";
import { AuthProvider } from "./contexts/authContext";
import RedirectToPrivacy from "./components/policies/RedirectToPrivacy"; // Import the RedirectToPrivacy component
import RedirectToTerms from "./components/policies/RedirectToTerms";

function App() {
  const [canAccessPlay, setCanAccessPlay] = useState(false);

  const allowAccessToPlay = () => {
    setCanAccessPlay(true);
  };

  useEffect(() => {
    // First we get the viewport height and we multiply it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    const handleResize = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={<HomePage allowAccessToPlay={allowAccessToPlay} />}
          />
          <Route
            path="/play"
            element={canAccessPlay ? <Play /> : <Navigate to="/" />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/privacy" element={<RedirectToPrivacy />} />{" "}
          <Route path="/terms" element={<RedirectToTerms />} />{" "}
          {/* Use the redirect component */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
