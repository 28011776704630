import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { FaQuestion, FaCheck, FaXmark } from "react-icons/fa6";
import ClockTimer from "./clock";
import Congratulation from "./congratulation";
import ExclamationModal from "./exclamationModal";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import ZoomableImage from "./ImageZoom";
import { Timestamp } from "@firebase/firestore";
import { createNewUserEntry } from "../../firebase/store";
import { Oval } from "react-loader-spinner";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { isMobile } from "react-device-detect";
import { getAnalytics, logEvent } from "firebase/analytics";
import "../../styles/questions.css";
import CongratulationModal from "./congratulationModal";

const analytics = getAnalytics();

const Play = () => {
  const divRef = useRef();
  const mobileImageRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isConfirmSkipModalOpen, setIsConfirmSkipModalOpen] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const [quizStart, setQuizStart] = useState(false);
  const [showQus, setShowQus] = useState(false);
  const [questions, setQuestions] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [imgSrc, setImgSrc] = useState("");

  const [count, setCount] = useState(0);
  const [running, setRunning] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showCongratulationModal, setShowCongratulationModal] = useState(false);
  const [aggregatedResults, setAggregatedResults] = useState(null);
  const [result, setResult] = useState(null);

  const [givenAnswers, setGivenAnswers] = useState([]);
  const [updateComplete, setUpdateComplete] = useState(false);
  const [currentUserAnswer, setCurrentUserAnswer] = useState("");

  const baseUrl = "https://d1nxxhrpkk2ck5.cloudfront.net";

  const appendAnswersForQuestion = (index, answer) => {
    setGivenAnswers((prevAnswers) => {
      setCurrentUserAnswer(answer);
      const newAnswers = [...prevAnswers];
      if (!newAnswers[index]) {
        newAnswers[index] = [];
      }
      newAnswers[index] = [...newAnswers[index], answer];

      localStorage.setItem("userAnswers", JSON.stringify(newAnswers));
      storUserState();
      return newAnswers;
    });

    // Set updateComplete to true
    setUpdateComplete(true);
  };

  useEffect(() => {
    if (updateComplete) {
      singleQuestionAnswerCheck();
      // Reset updateComplete to false after the check
      setUpdateComplete(false);
    }
  }, [updateComplete]);

  // Function to reset the givenAnswers array to an empty array
  const resetGivenAnswers = () => {
    setGivenAnswers([]);
    localStorage.setItem("userAnswers", JSON.stringify([]));
  };

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  // Function to update the width
  const updateWidth = () => {
    if (divRef.current) {
      setTimeout(() => {
        console.log("Current Width:", divRef.current.offsetWidth);
      }, 200);
    }
  };

  const debouncedUpdateWidth = debounce(updateWidth, 200);

  useLayoutEffect(() => {
    updateWidth();

    window.addEventListener("resize", debouncedUpdateWidth);
    return () => {
      window.removeEventListener("resize", debouncedUpdateWidth);
    };
  }, []);

  const transformWrapperRef = useRef(null);

  useEffect(() => {
    const handleOrientationChange = () => {
      // Recenter the image
      if (transformWrapperRef.current) {
        const wrapper = transformWrapperRef.current;
        wrapper.centerView(1);
      }
    };

    // Add event listener for orientation change
    window.addEventListener("resize", handleOrientationChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isTimerStarted) {
      timer = setTimeout(() => {
        pictureSeen(true);
      }, 20000); // 20000 milliseconds = 20 seconds
    }

    // Cleanup function to clear the timer
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isTimerStarted]);

  useEffect(() => {
    const fetchData = async () => {
      setIsApiLoading(true);
      try {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const dd = String(today.getDate()).padStart(2, "0");
        let dateString = `${yyyy}-${mm}-${dd}`;
        const isDebugMode = process.env.REACT_APP_DEBUG === "true";
        if (isDebugMode) {
          let targetDate = localStorage.getItem("targetDate");
          if (!targetDate) {
            targetDate = "2024-07-24";
            localStorage.setItem("targetDate", targetDate);
          }
          dateString = targetDate;
        }

        const fileUrl = `${baseUrl}/files/${dateString}.json`;
        const imageUrl = `${baseUrl}/images/${dateString}.png`;

        setImgSrc(imageUrl);

        const response = await fetch(fileUrl);
        if (!response.ok) {
          logEvent(analytics, "error", {
            status: response.status,
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        let UserGamePlayObject = localStorage.getItem("UserGamePlay");
        UserGamePlayObject = JSON.parse(UserGamePlayObject);
        setResult(UserGamePlayObject);

        let userAnswers = localStorage.getItem("userAnswers");
        userAnswers = JSON.parse(userAnswers);
        if (userAnswers) {
          setGivenAnswers(userAnswers);
        }

        // Fetch aggregated results
        try {
          const aggResponse = await fetch(
            "https://aggregateuserentries-o72kqu6ifa-uc.a.run.app",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                questions: data.game.questions,
                puzzle_id: data.id,
              }),
            }
          );

          if (!aggResponse.ok) {
            throw new Error(`HTTP error! status: ${aggResponse.status}`);
          }

          const aggData = await aggResponse.json();
          setAggregatedResults(aggData);
        } catch (error) {
          console.error("Error fetching aggregated results:", error);
        }

        if (UserGamePlayObject) {
          if (UserGamePlayObject.status === "COMPLETED") {
            setShowResult(true);
            setQuestions(data);
          } else {
            if (!showQus) {
              if (!UserGamePlayObject.hasSeenPicture) {
                setQuestions(data);
                setQuestionIndex(0);
                handleCountdown(data?.durationInSecs);
                setQuizStart(true);
                pictureSeen(false);
                const timet = setTimeout(() => {
                  updateWidth();
                }, 200);
                return () => clearTimeout(timet);
              } else {
                setQuestions(data);
                setQuestionIndex(UserGamePlayObject.currentQuestionIndex);
                setQuizStart(true);
                const timet = setTimeout(() => {
                  updateWidth();
                }, 200);
                return () => clearTimeout(timet);
              }
            }
          }
        } else {
          //brand new UserObject is null
          if (!showQus) {
            let UserGamePlayObject = {
              gameState: [],
              hasSeenPicture: false,
              currentQuestionIndex: 0,
              currentScore: 0,
              status: "IN_PROGRESS",
              lastCompleted: 0,
              lastPlayed: 0,
              gamesPlayed: 0,
              currentStreak: 0,
              isOnStreak: false,
              AvgScorePercentage: 0,
              puzzle_id: data.id,
              created_at: Date.now(),
            };

            // Get currentStreak from localStorage
            const storedCurrentStreak = localStorage.getItem("currentStreak");
            const storedGamesPlayed = localStorage.getItem("gamesPlayed");

            // Parse the stored value (localStorage stores everything as string, so parseInt is needed)
            const parsedCurrentStreak = parseInt(storedCurrentStreak, 10);
            const parsedGamesPlayed = parseInt(storedGamesPlayed, 10);

            // Check if the stored value is a valid number and greater than 0
            if (!isNaN(parsedCurrentStreak) && parsedCurrentStreak > 0) {
              UserGamePlayObject.currentStreak = parsedCurrentStreak;
            } else {
              UserGamePlayObject.currentStreak = 0; // Set to zero if the stored value is not valid or is zero
            }

            // Check if the stored value is a valid number and greater than 0
            if (!isNaN(parsedGamesPlayed) && parsedGamesPlayed > 0) {
              UserGamePlayObject.gamesPlayed = parsedGamesPlayed;
            } else {
              UserGamePlayObject.gamesPlayed = 0; // Set to zero if the stored value is not valid or is zero
            }

            localStorage.setItem(
              "UserGamePlay",
              JSON.stringify(UserGamePlayObject)
            );
            setResult(UserGamePlayObject);
            createNewUserEntry(UserGamePlayObject);

            setQuestions(data);
            setQuestionIndex(0);
            handleCountdown(data?.durationInSecs);
            setQuizStart(true);
            pictureSeen(false);
            const timet = setTimeout(() => {
              updateWidth();
            }, 200);

            return () => clearTimeout(timet);
          }
        }
      } catch (error) {
        console.error("Could not fetch the data", error);
      } finally {
        setIsApiLoading(false);
      }
    };

    fetchData();
  }, []);

  // Effect to manage overall loading state
  useEffect(() => {
    setIsLoading(isApiLoading || isImageLoading);
  }, [isApiLoading, isImageLoading]);

  const handleCountdown = (seconds) => {
    setCount(seconds);
    setRunning(true);
  };

  const handleSkip = () => {
    setIsConfirmSkipModalOpen(true);
  };

  const handleConfirmSkip = () => {
    setIsConfirmSkipModalOpen(false);
    logEvent(analytics, "skip", {
      count: count,
    });
    setCount(0);
    pictureSeen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsTimerStarted(true); // Start the timer when modal is closed
  };

  const pictureSeen = (skip) => {
    if (!skip) return;
    let userObject = JSON.parse(localStorage.getItem("UserGamePlay"));
    // const { game_data: { game, stats }, puzzle_id, timestamp } = userObject;
    userObject["hasSeenPicture"] = true;

    localStorage.setItem("UserGamePlay", JSON.stringify(userObject));
    createNewUserEntry(userObject);
  };

  if (quizStart) {
    if (count === 0) {
      setQuizStart(false);
      setShowQus(true);
      setIsConfirmSkipModalOpen(false); // Close the skip confirmation modal when timer ends
    }
  }

  const storUserState = () => {
    const userAnswers = JSON.parse(localStorage.getItem("userAnswers"));
    const userObject = JSON.parse(localStorage.getItem("UserGamePlay"));

    if (!userAnswers || !userObject) {
      console.error(
        "userAnswers or userObject is not available in localStorage"
      );
      return;
    }

    // Update gameState with userAnswers
    userObject.gameState = userAnswers;

    localStorage.setItem("UserGamePlay", JSON.stringify(userObject));
  };

  const changeQuestion = () => {
    setTimeout(() => {
      // Reset all button styles by forcing a re-render with a key change
      const buttons = document.querySelectorAll('.answer-button');
      buttons.forEach(button => {
        button.className = 'answer-button';
      });
      setQuestionIndex(questionIndex + 1);
    }, 1200);
  };

  const finalUpdate = () => {
    setTimeout(() => {
      resetGivenAnswers();
      setShowResult(true);
      setShowQus(false);
    }, 1000);
  };

  const getMessage = (currentTryNumber, isCorrect) => {
    if (!isCorrect) {
      return "Try again next time 😢";
    }
    const messages = ["Amazing! 😄", "Good Job! 👍", "Phew! 😅"];
    return messages[currentTryNumber - 1];
  };

  const showToast = (isCorrect, attemptCount) => {
    const message = getMessage(attemptCount, isCorrect);
    toast(message, {
      duration: 2000,
      style: {
        borderRadius: '99px',
        background: isCorrect ? '#06BF66' : '#CD0000',
        color: '#fff',
        padding: '12px 24px',
      },
    });
  };

  const singleQuestionAnswerCheck = () => {
    let UserObject = localStorage.getItem("UserGamePlay");
    UserObject = JSON.parse(UserObject);

    const {
      gameState,
      currentQuestionIndex,
      currentScore,
    } = UserObject;

    let newCurrentScore;

    switch (gameState[questionIndex]?.length) {
      case 1:
        newCurrentScore = currentScore + 3;
        break;
      case 2:
        newCurrentScore = currentScore + 2;
        break;
      case 3:
        newCurrentScore = currentScore + 1;
        break;
      default:
        newCurrentScore = currentScore + 3;
    }

    const isCorrect = currentUserAnswer === `${questions?.game?.questions[questionIndex]?.corr_ans}`;
    const isLastQuestion = questions?.game?.questions.length === questionIndex + 1;

    if (isLastQuestion) {
      if (isCorrect) {
        UserObject.currentQuestionIndex = 0;
        UserObject.currentScore = newCurrentScore;
        UserObject.status = "COMPLETED";
        UserObject.lastPlayed = Timestamp.fromDate(new Date());
        UserObject.lastCompleted = Timestamp.fromDate(new Date());
        UserObject.gamesPlayed = UserObject.gamesPlayed + 1;
        UserObject.currentStreak = UserObject.currentStreak + 1;
        UserObject.isOnStreak = true;
        UserObject.AvgScorePercentage = Math.round((newCurrentScore / 9) * 100);

        localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
        createNewUserEntry(UserObject);

        finalUpdate();
        showToast(true, gameState[questionIndex]?.length || 1);
      } else {
        if (!gameState[questionIndex] || gameState[questionIndex]?.length < 3) {
          UserObject.status = "IN_PROGRESS";
          UserObject.currentScore = currentScore;
          UserObject.lastPlayed = Timestamp.fromDate(new Date());

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);
        } else {
          UserObject.currentQuestionIndex = 0;
          UserObject.currentScore = currentScore;
          UserObject.status = "COMPLETED";
          UserObject.lastPlayed = Timestamp.fromDate(new Date());
          UserObject.lastCompleted = Timestamp.fromDate(new Date());
          UserObject.gamesPlayed = UserObject.gamesPlayed + 1;
          if (currentScore > 0) {
            UserObject.isOnStreak = true;
            UserObject.currentStreak = UserObject.currentStreak + 1;
          } else {
            UserObject.isOnStreak = false;
            UserObject.currentStreak = 0;
          }

          UserObject.AvgScorePercentage = Math.round(
            (newCurrentScore / 9) * 100
          );

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);

          finalUpdate();
          showToast(false, 3);
        }
      }
    } else {
      if (isCorrect) {
        UserObject.currentQuestionIndex = currentQuestionIndex + 1;
        UserObject.currentScore = newCurrentScore;
        UserObject.status = "IN_PROGRESS";
        UserObject.lastPlayed = Timestamp.fromDate(new Date());

        localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
        createNewUserEntry(UserObject);

        changeQuestion();
        showToast(true, gameState[questionIndex]?.length || 1);
      } else {
        if (!gameState[questionIndex] || gameState[questionIndex]?.length < 3) {
          UserObject.status = "IN_PROGRESS";
          UserObject.currentScore = currentScore;
          UserObject.lastPlayed = Timestamp.fromDate(new Date());

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);
        } else {
          UserObject.currentQuestionIndex = currentQuestionIndex + 1;
          UserObject.currentScore = currentScore;
          UserObject.status = "IN_PROGRESS";
          UserObject.lastPlayed = Timestamp.fromDate(new Date());

          localStorage.setItem("UserGamePlay", JSON.stringify(UserObject));
          createNewUserEntry(UserObject);

          changeQuestion();
          showToast(false, 3);
        }
      }
    }
  };

  // Reset loading state when image source changes
  useEffect(() => {
    if (imgSrc) {
      console.log('Play: New image source set:', imgSrc);
      setIsImageLoading(true);
      
      // Preload image for both mobile and desktop
      console.log('Play: Preloading image');
      const img = new Image();
      img.onload = () => {
        console.log('Play: Image loaded');
        handleImageLoad();
      };
      img.src = imgSrc;
    }
  }, [imgSrc]);

  const handleImageLoad = () => {
    console.log('Play: handleImageLoad called');
    requestAnimationFrame(() => {
      console.log('Play: Setting isImageLoading to false');
      setIsImageLoading(false);
    });
  };

  // Debug loading states
  useEffect(() => {
    console.log('Play: Loading states updated:', {
      isApiLoading,
      isImageLoading,
      isLoading,
      imgSrc: !!imgSrc,
      isMobile
    });
  }, [isApiLoading, isImageLoading, isLoading, imgSrc]);

  //#######################################################
  //#######################################################
  //Display code
  //#######################################################
  //#######################################################

  // Add debug logging for state changes
  useEffect(() => {
    if (showCongratulationModal) {
      console.log('Modal State:', {
        showModal: showCongratulationModal,
        hasAggregatedResults: !!aggregatedResults,
        hasQuestions: !!questions,
        hasResult: !!result
      });
    }
  }, [showCongratulationModal, aggregatedResults, questions, result]);

  return (
    <div className="play-container">
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner">
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#000000"
              secondaryColor="#E3E3E1"
              ariaLabel="oval-loading"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </div>
        </div>
      ) : (
        <div className="main-app-div">
          {quizStart && (
            <>
              {imgSrc ? (
                <div>
                  {!isMobile ? (
                    <div className="home-main-div" style={{ maxWidth: "auto" }}>
                      <FaQuestion
                        className={"exclamation-icon"}
                        onClick={() => setIsModalOpen(true)}
                      />
                      <ExclamationModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                      />

                      {isTimerStarted && (
                        <ClockTimer
                          count={count}
                          running={running}
                          setCount={setCount}
                          isMobile={isMobile}
                        />
                      )}

                      <button
                        className={"skip-btn"}
                        onClick={handleSkip}
                      >
                        Skip
                      </button>
                      <div
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100vh",
                          padding: "1.5%",
                        }}
                        ref={divRef}
                      >
                        <ZoomableImage
                          src={imgSrc}
                          imgW="100%"
                          imgH="100vh"
                          onLoad={handleImageLoad}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <FaQuestion
                        className={"exclamation-icon-mobile"}
                        onClick={() => setIsModalOpen(true)}
                      />
                      <ExclamationModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                      />

                      {isTimerStarted && (
                        <ClockTimer
                          count={count}
                          running={running}
                          setCount={setCount}
                          isMobile={isMobile}
                        />
                      )}

                      <button
                        className={"skip-btn-mobile"}
                        onClick={handleSkip}
                      >
                        Skip
                      </button>

                      <div
                        style={{
                          position: "fixed",
                          width: "98%",
                          height: "98%",
                          height: `calc(${98 * window.innerHeight * 0.01}px)`,
                          border: "1px solid rgb(200, 200, 200)",
                          borderRadius: "20px",
                          overflow: "clip",
                          top: "1%",
                          left: "1%",
                        }}
                      >
                        <TransformWrapper centerOnInit ref={transformWrapperRef}>
                          <TransformComponent
                            wrapperStyle={{
                              width: "100%",
                              background: "rgb(247 243 243 / 57%)",
                              height: "100%",
                            }}
                          >
                            <img
                              ref={mobileImageRef}
                              src={imgSrc}
                              alt="test"
                              width="100%"
                              height="100%"
                              onLoad={handleImageLoad}
                              style={{ display: 'block' }}
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="spinner-container">
                  <div className="spinner">
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#000000"
                      secondaryColor="#E3E3E1"
                      ariaLabel="oval-loading"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showQus && (
        <div className="questions-container">
          {/* Progress Bar */}
          <div className="progress-container">
            <div className="progress-background">
              <div 
                className="progress-fill"
                style={{ width: `${((questionIndex + 1) / questions?.game?.questions.length) * 100}%` }}
              />
            </div>
            <div className="progress-text">
              Question {questionIndex + 1} of {questions?.game?.questions.length}
            </div>
          </div>

          <div className="questions-content">
            <div className="question-card fade-in" key={questionIndex}>
              <div className="question-number">Q{questionIndex + 1}.</div>
              <div className="question-text">
                {questions?.game?.questions[questionIndex].question}
              </div>

              <div className="answers-container">
                {questions?.game?.questions[questionIndex].answers.map((answer, index) => {
                  const isAnswered = givenAnswers[questionIndex]?.includes(answer);
                  const isCorrect = answer === questions?.game?.questions[questionIndex]?.corr_ans;
                  const hasThreeAttempts = givenAnswers[questionIndex]?.length >= 3;
                  const hasCorrectAnswer = givenAnswers[questionIndex]?.includes(questions?.game?.questions[questionIndex]?.corr_ans);
                  const isQuestionComplete = hasThreeAttempts || hasCorrectAnswer;
                  
                  return (
                    <motion.button
                      key={index}
                      onClick={() => {
                        if (!isAnswered && !isQuestionComplete && (!givenAnswers[questionIndex] || givenAnswers[questionIndex].length < 3)) {
                          appendAnswersForQuestion(questionIndex, answer);
                        }
                      }}
                      disabled={isAnswered || isQuestionComplete}
                      className={`answer-button ${
                        isAnswered ? (isCorrect ? 'correct-answer' : 'wrong-answer') : ''
                      } ${isAnswered ? 'answered' : ''} ${isQuestionComplete && !isAnswered ? 'disabled' : ''}`}
                      whileTap={{ scale: 0.95 }}
                      transition={{ type: "spring", stiffness: 400, damping: 17 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                    >
                      <span className="answer-text">
                        {String.fromCharCode(65 + index)}. {answer}
                      </span>
                      {isAnswered && (
                        <motion.div 
                          className="result-indicator"
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        >
                          <span className="indicator-text">
                            {isCorrect ? <FaCheck /> : <FaXmark />}
                          </span>
                        </motion.div>
                      )}
                    </motion.button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {showResult && <Congratulation questions={questions?.game?.questions} />}
      <Toaster />

      {/* Skip Confirmation Modal */}
      {isConfirmSkipModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="text-center space-y-6">
              <h3 className="text-2xl font-bold text-black">Are you sure?</h3>
              <p className="text-base text-black">
                Have you memorized the picture? Are you sure you want to skip?
              </p>
              <div className="flex gap-4 justify-center">
                <button
                  onClick={() => setIsConfirmSkipModalOpen(false)}
                  className="px-8 py-2.5 rounded-full border border-black bg-transparent text-black font-medium text-base
                    hover:bg-black hover:text-white transition-colors duration-200"
                >
                  No
                </button>
                <button
                  onClick={handleConfirmSkip}
                  className="px-8 py-2.5 rounded-full bg-black text-white font-medium text-base
                    hover:bg-[#E3E3E1] hover:text-black hover:border hover:border-black transition-colors duration-200"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {questions && aggregatedResults && result && (
        <CongratulationModal
          showCongratulationModal={showCongratulationModal}
          setCongratulationModal={setShowCongratulationModal}
          givenQuestions={questions}
          aggregatedResults={aggregatedResults}
          result={result}
        />
      )}
    </div>
  );
};

export default Play;
