import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IoClose, IoPerson, IoPeople, IoStatsChart, IoChevronForward, IoShareOutline } from "react-icons/io5";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import arrowImage from "../../media/arrow.png";
import "../../styles/congratulations.css";

const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

const CountdownTimer = () => {
  const [countdownTime, setCountdownTime] = useState("");

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(24, 0, 0, 0);
      const diff = midnight.getTime() - now.getTime();

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setCountdownTime(
        `${hours} hour${hours !== 1 ? "s" : ""}, ${minutes} minute${
          minutes !== 1 ? "s" : ""
        } and ${seconds} second${seconds !== 1 ? "s" : ""}`
      );
    };

    const timer = setInterval(calculateCountdown, 1000);
    calculateCountdown();

    return () => clearInterval(timer);
  }, []);

  return (
    <>

      <p className="countdown-text">
        {countdownTime}
      </p>
    </>
  );
};

const ComparisonModal = ({
  visible,
  onClose,
  aggregatedResults,
  sliderData,
  result
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const countdownTime = CountdownTimer();

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight') {
        handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrev();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentSlide, sliderData.length]);

  const onTouchStart = useCallback((e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove = useCallback((e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe && currentSlide < sliderData.length + 1) {
      handleNext();
    }
    if (isRightSwipe && currentSlide > 0) {
      handlePrev();
    }
  }, [touchStart, touchEnd, currentSlide, sliderData.length]);

  const handleNext = useCallback(() => {
    if (currentSlide < sliderData.length + 1) {
      setCurrentSlide(prev => prev + 1);
    }
  }, [currentSlide, sliderData.length]);

  const handlePrev = useCallback(() => {
    if (currentSlide > 0) {
      setCurrentSlide(prev => prev - 1);
    }
  }, [currentSlide]);

  const renderFirstSlide = () => {
    const userScore = result?.AvgScorePercentage || 0;
    const scoresList = aggregatedResults?.orderedListOfScores || [];
    const totalScores = scoresList.length;

    let rank = totalScores;
    for (let i = 0; i < totalScores; i++) {
      if (userScore >= scoresList[i]) {
        rank = i + 1;
      }
    }

    const topPercentage = Math.round(((totalScores - rank) / totalScores) * 100 + 1);

    return (
      <div className="carousel-item first-card">
        <div className="congrats-content">
          <h2 className="congrats-title">Congratulations!</h2>
          <p className="congrats-text">
            Based on your answers, you are currently in the TOP
          </p>
          <div className="ranking-percentage">{topPercentage}%</div>
        </div>
        <div className="swipe-text-container">
          <p className="swipe-text">
            Swipe Right To See What Other Players Did
          </p>
        </div>
      </div>
    );
  };

  const renderQuestionSlide = useCallback((item) => {
    if (!item) {
      console.log('ERROR: Missing slide data');
      return null;
    }

    const totalResponses = item.trial_stats[0]?.reduce((sum, count) => sum + count, 0) || 1;

    return (
      <div className="carousel-item">
        <div className="question-header">
          <span className="question-number">Question {item.questionNumber + 1}</span>
          <span className="trial-number">Trial {item.tryNumber + 1}</span>
        </div>
        
        <div className="question-content">
          <p className="question-text">{item.question}</p>
          
          <div className="answer-stats">
            {item.answers.map((answer, ansIndex) => {
              const responseCount = item.trial_stats[0]?.[ansIndex] || 0;
              const percentage = Math.round((responseCount / totalResponses) * 100);
              const isCorrect = answer === item.corr_ans;
              const isMyAnswer = answer === item.MyTry;

              return (
                <div key={ansIndex} className={`answer-stat ${isCorrect ? 'correct' : ''}`}>
                  <div className="percentage-bar">
                    <div 
                      className="percentage-fill"
                      style={{ width: `${percentage}%` }}
                    />
                  </div>
                  
                  <div className="answer-content">
                    <div className="answer-text-container">
                      <span className="answer-text">
                        {String.fromCharCode(65 + ansIndex)}. {answer}
                      </span>
                    </div>
                    
                    <div className="answer-right-content">
                      {isMyAnswer && (
                        <div className="person-icon">
                          <IoPerson size={16} color={isCorrect ? "#fff" : "#000"} />
                        </div>
                      )}
                      <div className="response-count">
                        <IoPeople size={14} color={isCorrect ? "#fff" : "#666"} />
                        <span className="response-count-text">
                          {formatNumber(responseCount)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="legend">
          <div className="legend-item">
            <IoPerson size={16} color="#000" />
            <span className="legend-text">Your answer</span>
          </div>
          <div className="legend-item">
            <IoPeople size={16} color="#000" />
            <span className="legend-text">Total responses</span>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderFinalSlide = () => (
    <div className="carousel-item first-card">
      <div className="congrats-content">
        <h2 className="congrats-title">Catch you tomorrow! 👋</h2>
        <p className="congrats-text">
          The next puzzle will be out in {countdownTime}
        </p>
      </div>
    </div>
  );

  const data = ['stats', ...sliderData, 'final'];

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      className="carousel-modal-content"
      overlayClassName="carousel-modal-overlay"
    >
      <div className="carousel-modal-header">
        <h3 className="carousel-modal-title">Your Performance</h3>
        <button 
          className="carousel-close-button"
          onClick={onClose}
        >
          <IoClose size={24} />
        </button>
      </div>

      {aggregatedResults ? (
        <div className="carousel-wrapper">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
              className="carousel"
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              {currentSlide === 0 && renderFirstSlide()}
              {currentSlide > 0 && currentSlide <= sliderData.length && renderQuestionSlide(sliderData[currentSlide - 1])}
              {currentSlide === sliderData.length + 1 && renderFinalSlide()}
            </motion.div>
          </AnimatePresence>

          <button
            className="carousel-nav-button prev"
            onClick={handlePrev}
            disabled={currentSlide === 0}
          >
            <img src={arrowImage} alt="Previous" />
          </button>

          <button
            className="carousel-nav-button next"
            onClick={handleNext}
            disabled={currentSlide === sliderData.length + 1}
          >
            <img src={arrowImage} alt="Next" />
          </button>

          <div className="pagination-container">
            {Array(data.length).fill(0).map((_, index) => (
              <div
                key={index}
                className={`pagination-dot ${currentSlide === index ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <p>Loading statistics...</p>
      )}
    </Modal>
  );
};

const ScoreCalculationModal = ({ visible, onClose }) => (
  <Modal
    isOpen={visible}
    onRequestClose={onClose}
    className="score-modal-content"
    overlayClassName="score-modal-overlay"
  >
    <div className="score-modal-header">
      <h3 className="modal-title">Score Calculation</h3>
      <IoClose
        onClick={onClose}
        className="modal-close"
        aria-label="Close modal"
      />
    </div>
    <div className="score-modal-body">
      <p className="modal-subtitle">
        The score is calculated based on your performance:
      </p>
      <ul className="modal-ul-bullets">
        <li>You start with 3 points for each question.</li>
        <li>If you answer correctly on the first try, you keep all 3 points.</li>
        <li>If you get it wrong, you lose 1 point for each wrong answer.</li>
        <li>After 3 wrong attempts, you get 0 points for that question.</li>
        <li>Your total score is the percentage of points you earned out of the maximum possible points.</li>
      </ul>
    </div>
  </Modal>
);

const Congratulation = ({ questions }) => {
  const [result, setResult] = useState(null);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [aggregatedResults, setAggregatedResults] = useState(null);
  const initializedRef = useRef(false);

  useEffect(() => {
    const loadResult = async () => {
      const userGamePlay = localStorage.getItem('UserGamePlay');
      if (userGamePlay) {
        const userObject = JSON.parse(userGamePlay);
        setResult(userObject);

      try {
        const response = await fetch(
          "https://aggregateuserentries-o72kqu6ifa-uc.a.run.app",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              questions,
                puzzle_id: userObject.puzzle_id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

          const aggData = await response.json();
          setAggregatedResults(aggData);

          // Process slider data when aggregated results are available
          if (aggData?.trialStats) {
            const slides = [];
            questions.forEach((question, qIndex) => {
              aggData.trialStats[qIndex].forEach((trialStat, tIndex) => {
                slides.push({
                  question: question.question,
                  answers: question.answers,
                  corr_ans: question.corr_ans,
                  MyTry: userObject.gameState[qIndex]?.[tIndex],
                  questionNumber: qIndex,
                  tryNumber: tIndex,
                  trial_stats: [trialStat],
                });
              });
            });
            setSliderData(slides);
          }
      } catch (error) {
        console.error("Error fetching aggregated results:", error);
        }
      }
    };

    if (!initializedRef.current) {
      loadResult();
      initializedRef.current = true;
    }
  }, [questions]);

  const evaluateAnswers = (givenAnswers, questions) => {
    return questions
      .map((_, index) => {
        const trials = Array(3)
          .fill("⚪️")
          .map((placeholder, attempt) => {
            return givenAnswers[index]?.[attempt] !== undefined
              ? givenAnswers[index][attempt] === questions[index].corr_ans
                ? "🟢"
                : "🔴"
              : placeholder;
          })
          .join(" ");
        return `Q${index + 1}: ${trials}`;
      })
      .join("\n");
  };

  const copyToClipboard = () => {
    const scorePercentage = Math.round(
      (result?.currentScore / (questions.length * 3)) * 100
    );

    const formattedText =
      "Fragments of Memory 🧠\n" +
      "Score: " +
      scorePercentage +
      "%. My trials:\n" +
      evaluateAnswers(result?.gameState, questions) +
      "\nTry your turn at https://FragmentsOfMemory.com";

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        toast.success("Results copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy: ", err);
      });
  };

  if (!result) {
    return <div>Loading...</div>;
  }

  return (
      <div className="congratulations-container">
      <div className="content">
        <div className="main-content">
          <div className="header-section">
            <h1 className="title">
              {result?.currentScore === 0 ? "Try Harder Next Time" : "Congratulations!"}
            </h1>
            <p className="subtitle">Your Statistics</p>

            <div className="stats-container">
              <div className="stat-item">
                <p className="stat-number">{result?.gamesPlayed || 1}</p>
                <p className="stat-label">Played</p>
        </div>

              <div className="stat-item">
                <p className="stat-number">
                  {Math.round((result?.currentScore / 9) * 100)}
                </p>
                <p className="stat-label">Score %</p>
        </div>

              <div className="stat-item">
                <p className="stat-number">{result?.currentStreak || 0}</p>
                <p className="stat-label">Current Streak</p>
              </div>
          </div>
          </div>

          <div className="actions-section">
            <button 
              className="compare-button"
              onClick={() => setShowCompareModal(true)}
            >
              <div className="compare-content">
                <IoStatsChart size={24} />
                <span className="compare-text">
                  See your results compared to everyone else!
                </span>
                <IoChevronForward size={24} />
              </div>
            </button>

            <button 
              className="how-to-calculate-button"
              onClick={() => setShowScoreModal(true)}
            >
              <span className="how-to-calculate-text">
                How the score is calculated
              </span>
            </button>

              <button
              className="share-button"
              onClick={copyToClipboard}
            >
              <span className="share-button-text">Share</span>
              <IoShareOutline size={20} className="share-icon" />
              </button>
              <p className="countdown-text">
        The next puzzle will be out in
      </p>
            <CountdownTimer />
          </div>
        </div>
      </div>

      <ScoreCalculationModal 
        visible={showScoreModal} 
        onClose={() => setShowScoreModal(false)} 
      />

      <ComparisonModal
        visible={showCompareModal}
        onClose={() => setShowCompareModal(false)}
        aggregatedResults={aggregatedResults}
        sliderData={sliderData}
        result={result}
      />
    </div>
  );
};

export default Congratulation;
