import React, { useState } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext';
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth';
import { mapErrorMessage } from '../login/errorMessages';

const Register = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }
        try {
            if (!isRegistering) {
                setIsRegistering(true);
                await doCreateUserWithEmailAndPassword(email, password);
                navigate('/');
            }
        } catch (err) {
            console.error(err);
            setErrorMessage(mapErrorMessage(err.code, err.message));
            setIsRegistering(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#E3E3E1] flex flex-col">
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}

            <div className="flex-1 flex items-center justify-center px-4">
                <div className="w-full max-w-md bg-white rounded-3xl shadow-lg p-8 space-y-6">
                    <div className="space-y-6">
                        <Link 
                            to="/" 
                            className="block text-sm text-black hover:underline"
                        >
                            ← Back to Home
                        </Link>
                        <div className="text-center">
                            <h3 className="text-2xl font-bold text-black">Create a New Account</h3>
                        </div>
                    </div>

                    <form onSubmit={onSubmit} className="space-y-5">
                        <div>
                            <label className="text-sm font-medium text-black">
                                Email
                            </label>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full mt-2 px-4 py-3 rounded-full border border-gray-300 focus:border-black focus:ring-0 text-black bg-transparent"
                            />
                        </div>

                        <div>
                            <label className="text-sm font-medium text-black">
                                Password
                            </label>
                            <input
                                disabled={isRegistering}
                                type="password"
                                autoComplete='new-password'
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full mt-2 px-4 py-3 rounded-full border border-gray-300 focus:border-black focus:ring-0 text-black bg-transparent"
                            />
                        </div>

                        <div>
                            <label className="text-sm font-medium text-black">
                                Confirm Password
                            </label>
                            <input
                                disabled={isRegistering}
                                type="password"
                                autoComplete='off'
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full mt-2 px-4 py-3 rounded-full border border-gray-300 focus:border-black focus:ring-0 text-black bg-transparent"
                            />
                        </div>

                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="terms-checkbox"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                className="mr-2 rounded border-gray-300 text-black focus:ring-black"
                            />
                            <label htmlFor="terms-checkbox" className="text-sm text-black">
                                I have read and agree to the{' '}
                                <a
                                    href="https://www.fragmentsofmemory.com/terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-[#007AFF] hover:underline"
                                >
                                    full Terms & Conditions
                                </a>
                            </label>
                        </div>

                        {errorMessage && (
                            <p className="text-red-600 text-sm font-medium text-center">{errorMessage}</p>
                        )}

                        <button
                            type="submit"
                            disabled={!isChecked || isRegistering}
                            className="w-full py-3 px-4 rounded-full bg-black text-white font-medium text-base
                                hover:bg-[#E3E3E1] hover:text-black hover:border hover:border-black 
                                transition-colors duration-200 disabled:bg-gray-300 disabled:hover:bg-gray-300 
                                disabled:text-gray-500 disabled:border-gray-300"
                        >
                            {isRegistering ? 'Signing Up...' : 'Sign Up'}
                        </button>

                        <div className="text-center">
                            <Link to="/login" className="text-sm text-black hover:underline">
                                Already have an account? <span className="font-bold">Continue</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
