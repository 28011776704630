import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IoClose, IoPerson, IoPeople, IoChevronForward } from "react-icons/io5";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";
import "../../styles/congratulations.css";

const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

const CountdownTimer = () => {
  const [countdownTime, setCountdownTime] = useState("");

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(24, 0, 0, 0);
      const diff = midnight.getTime() - now.getTime();

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setCountdownTime(
        `${hours} hour${hours !== 1 ? "s" : ""}, ${minutes} minute${
          minutes !== 1 ? "s" : ""
        } and ${seconds} second${seconds !== 1 ? "s" : ""}`
      );
    };

    const timer = setInterval(calculateCountdown, 1000);
    calculateCountdown();

    return () => clearInterval(timer);
  }, []);

  return countdownTime;
};

const CongratulationModal = ({
  showCongratulationModal,
  setCongratulationModal,
  givenQuestions,
  aggregatedResults,
  result
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderData, setSliderData] = useState([]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const countdownTime = CountdownTimer();
  const initializedRef = useRef(false);

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  // Add keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight') {
        handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrev();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentSlide, sliderData.length]);

  const onTouchStart = useCallback((e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove = useCallback((e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe && currentSlide < sliderData.length + 1) {
      handleNext();
    }
    if (isRightSwipe && currentSlide > 0) {
      handlePrev();
    }
  }, [touchStart, touchEnd, currentSlide, sliderData.length]);

  const handleNext = useCallback(() => {
    if (currentSlide < sliderData.length + 1) {
      setCurrentSlide(prev => prev + 1);
    }
  }, [currentSlide, sliderData.length]);

  const handlePrev = useCallback(() => {
    if (currentSlide > 0) {
      setCurrentSlide(prev => prev - 1);
    }
  }, [currentSlide]);

  // Main data processing effect
  useEffect(() => {
    // Only process if modal is shown and we haven't initialized yet
    if (!showCongratulationModal || initializedRef.current) {
      console.log('Skipping data processing:', {
        showModal: showCongratulationModal,
        isInitialized: initializedRef.current
      });
      return;
    }

    // Validate required data
    const hasRequiredData = aggregatedResults?.trialStats && 
                          givenQuestions?.game?.questions && 
                          result?.gameState;

    console.log('Data validation:', {
      hasTrialStats: !!aggregatedResults?.trialStats,
      hasQuestions: !!givenQuestions?.game?.questions,
      hasGameState: !!result?.gameState,
      isValid: hasRequiredData
    });

    if (!hasRequiredData) {
      console.warn('Missing required data for modal');
      return;
    }

    // Process questions and create slides
    const questions = [];
    const questionsData = givenQuestions.game.questions;
    
    console.log('Starting data processing:', {
      questionCount: questionsData.length,
      trialStatsLength: aggregatedResults.trialStats.length
    });

    questionsData.forEach((question, qIndex) => {
      const trialStatsForQuestion = aggregatedResults.trialStats[qIndex];
      const userAnswers = result.gameState[qIndex];
      
      console.log(`Question ${qIndex + 1} data:`, {
        hasTrialStats: !!trialStatsForQuestion,
        trialStatsLength: trialStatsForQuestion?.length,
        hasUserAnswers: !!userAnswers,
        questionText: question.question?.substring(0, 30) + '...'
      });

      if (Array.isArray(trialStatsForQuestion)) {
        trialStatsForQuestion.forEach((trialStat, tIndex) => {
          if (trialStat || userAnswers?.[tIndex]) {
            questions.push({
              question: question.question,
              answers: question.answers,
              corr_ans: question.corr_ans,
              MyTry: userAnswers?.[tIndex],
              questionNumber: qIndex,
              tryNumber: tIndex,
              trial_stats: [trialStat]
            });

            console.log(`Added slide for Q${qIndex + 1} T${tIndex + 1}:`, {
              hasTrialStats: !!trialStat,
              userAnswer: userAnswers?.[tIndex]
            });
          }
        });
      }
    });

    console.log('Slides generation complete:', {
      totalSlides: questions.length,
      firstSlideQuestion: questions[0]?.question?.substring(0, 30) + '...'
    });

    setSliderData(questions);
    initializedRef.current = true;
  }, [showCongratulationModal, aggregatedResults, givenQuestions, result]);

  // Reset state when modal closes
  useEffect(() => {
    if (!showCongratulationModal) {
      console.log('Resetting modal state');
      initializedRef.current = false;
      setCurrentSlide(0);
      setSliderData([]);
    }
  }, [showCongratulationModal]);

  // Add click navigation buttons
  const renderNavigationButtons = () => (
    <div style={{ 
      position: 'absolute', 
      top: '50%', 
      left: 0, 
      right: 0, 
      display: 'flex', 
      justifyContent: 'space-between',
      padding: '0 20px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      zIndex: 2
    }}>
      <button
        onClick={handlePrev}
        disabled={currentSlide === 0}
        style={{
          padding: '10px 15px',
          background: 'rgba(0,0,0,0.5)',
          border: 'none',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          pointerEvents: 'auto',
          opacity: currentSlide === 0 ? 0.5 : 1
        }}
      >
        ←
      </button>
      <button
        onClick={handleNext}
        disabled={currentSlide === sliderData.length + 1}
        style={{
          padding: '10px 15px',
          background: 'rgba(0,0,0,0.5)',
          border: 'none',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          pointerEvents: 'auto',
          opacity: currentSlide === sliderData.length + 1 ? 0.5 : 1
        }}
      >
        →
      </button>
    </div>
  );

  const renderFirstSlide = () => {
    const userScore = result?.AvgScorePercentage || 0;
    const scoresList = aggregatedResults?.orderedListOfScores || [];
    const totalScores = scoresList.length;

    let rank = totalScores;
    for (let i = 0; i < totalScores; i++) {
      if (userScore >= scoresList[i]) {
        rank = i + 1;
      }
    }

    const topPercentage = Math.round(((totalScores - rank) / totalScores) * 100 + 1);

    return (
      <div className="carousel-item first-card">
        <div className="congrats-content">
          <h2 className="congrats-title">Congratulations!</h2>
          <p className="congrats-text">
            Based on your answers, you are currently in the TOP
          </p>
          <div className="ranking-percentage">{topPercentage}%</div>
        </div>
        <div className="swipe-text-container">
          <p className="swipe-text">
            Swipe Right To See What Other Players Did
          </p>
        </div>
      </div>
    );
  };

  const renderQuestionSlide = useCallback((item) => {
    if (!item) {
      console.log('ERROR: Missing slide data');
      return null;
    }

    const totalResponses = item.trial_stats[0]?.reduce((sum, count) => sum + count, 0) || 1;

    return (
      <div className="carousel-item">
        <div className="question-header">
          <span className="question-number">Question {item.questionNumber + 1}</span>
          <span className="trial-number">Trial {item.tryNumber + 1}</span>
        </div>
        <p className="question-text">{item.question}</p>
        
        <div className="answer-stats">
          {item.answers.map((answer, ansIndex) => {
            const responseCount = item.trial_stats[0]?.[ansIndex] || 0;
            const percentage = Math.round((responseCount / totalResponses) * 100);
            const isCorrect = answer === item.corr_ans;
            const isMyAnswer = answer === item.MyTry;

            return (
              <div key={ansIndex} className={`answer-stat ${isCorrect ? 'correct' : ''}`}>
                <div className="percentage-bar">
                  <div 
                    className="percentage-fill"
                    style={{ width: `${percentage}%` }}
                  />
                </div>
                
                <div className="answer-content">
                  <div className="answer-text-container">
                    <span className="answer-text">
                      {String.fromCharCode(65 + ansIndex)}. {answer}
                    </span>
                  </div>
                  
                  <div className="answer-right-content">
                    {isMyAnswer && (
                      <div className="person-icon">
                        <IoPerson size={16} color={isCorrect ? "#fff" : "#000"} />
                      </div>
                    )}
                    <div className="response-count">
                      <IoPeople size={14} color={isCorrect ? "#fff" : "#666"} />
                      <span className="response-count-text">
                        {formatNumber(responseCount)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="legend">
          <div className="legend-item">
            <IoPerson size={16} color="#000" />
            <span className="legend-text">Your answer</span>
          </div>
          <div className="legend-item">
            <IoPeople size={16} color="#000" />
            <span className="legend-text">Total responses</span>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderFinalSlide = () => (
    <div className="carousel-item first-card">
      <div className="congrats-content">
        <h2 className="congrats-title">Catch you tomorrow! 👋</h2>
        <p className="congrats-text">
          The next puzzle will be out in {countdownTime}
        </p>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={showCongratulationModal}
      onRequestClose={() => setCongratulationModal(false)}
      className="carousel-modal-content"
      overlayClassName="carousel-modal-overlay"
    >
      <div className="carousel-modal-header">
        <h3 className="carousel-modal-title">Your Performance</h3>
        <button 
          className="carousel-close-button"
          onClick={() => setCongratulationModal(false)}
        >
          <IoClose size={24} />
        </button>
      </div>

      {aggregatedResults ? (
        <div className="carousel-wrapper">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
              className="carousel"
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              {currentSlide === 0 && renderFirstSlide()}
              {currentSlide > 0 && currentSlide <= sliderData.length && renderQuestionSlide(sliderData[currentSlide - 1])}
              {currentSlide === sliderData.length + 1 && renderFinalSlide()}
            </motion.div>
          </AnimatePresence>

          {renderNavigationButtons()}

          <div className="pagination-container">
            {Array(sliderData.length + 2).fill(0).map((_, index) => (
              <div
                key={index}
                className={`pagination-dot ${currentSlide === index ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <p>Loading statistics...</p>
      )}
    </Modal>
  );
};

export default CongratulationModal;
